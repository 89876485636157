import type { ProviderCredential, UpdateProviderCredentialPayload } from 'tela-api/src/database/schemas/provider-credential'

export function useProviderCredentials() {
    const { api } = useAPI()
    const notification = useToast()

    const providerCredentials = useState<ProviderCredential[]>('provider-credentials', () => [])
    const isFetchingProviderCredentials = useState<boolean>('is-fetching-provider-credentials', () => false)

    const providerToCredentialMapping = computed(() => providerCredentials.value.reduce(
        (acc, credential) => ({ ...acc, [credential.providerId]: credential }),
        {} as Record<string, ProviderCredential>,
    ))

    async function fetchProviderCredentials() {
        const { currentWorkspace } = useWorkspaces()

        isFetchingProviderCredentials.value = true

        if (!currentWorkspace.value)
            return

        const credentials = await api()['provider-credential'].get({
            $query: { workspaceId: currentWorkspace.value.id },
        })

        if (credentials.error) {
            notification.add({
                title: 'Failed to fetch provider credentials',
                description: credentials.error.message,
                color: 'red',
            })
            return
        }
        providerCredentials.value = credentials.data
        isFetchingProviderCredentials.value = false
        return providerCredentials.value
    }

    async function createProviderCredential(token: string, providerId: string) {
        const { currentWorkspace } = useWorkspaces()
        if (!currentWorkspace.value)
            return

        const credential = await api()['provider-credential'].post({
            token,
            providerId,
            workspaceId: currentWorkspace.value.id,
        })

        if (credential.error) {
            notification.add({
                title: 'Failed to create provider credential',
                description: credential.error.message,
                color: 'red',
            })
            return
        }

        providerCredentials.value.push(credential.data)

        return credential
    }

    async function updateProviderCredential(id: string, update: UpdateProviderCredentialPayload) {
        const updatedCredential = await api()['provider-credential'][id].patch(update)

        if (updatedCredential.error) {
            notification.add({
                title: 'Failed to update provider credential',
                description: updatedCredential.error.message,
                color: 'red',
            })
            return
        }

        providerCredentials.value = providerCredentials.value.map(credential => credential.id === updatedCredential.data.id ? updatedCredential.data : credential)

        return updatedCredential
    }

    async function deleteProviderCredential(id: string) {
        const deletedCredential = await api()['provider-credential'][id].delete()

        if (deletedCredential.error) {
            notification.add({
                title: 'Failed to delete provider credential',
                description: deletedCredential.error.message,
                color: 'red',
            })
            return
        }

        providerCredentials.value = providerCredentials.value.filter(credential => credential.id !== id)
    }

    async function initializeProviderCredentials() {
        const { currentWorkspace } = useWorkspaces()
        watchEffect(async () => {
            if (currentWorkspace.value)
                await fetchProviderCredentials()
        })
    }

    return {
        providerCredentials,
        providerToCredentialMapping,
        fetchProviderCredentials,
        createProviderCredential,
        updateProviderCredential,
        deleteProviderCredential,
        initializeProviderCredentials,
        isFetchingProviderCredentials,
    }
}
